import React from 'react';
import { Row, Col, Typography, Grid } from 'antd';
import { createStyle } from '../../utils/tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faApple,
  faAndroid,
  faGooglePlay,
} from '@fortawesome/free-brands-svg-icons';
import '../i18n';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import Footer from './Footer';

const { Title, Text } = Typography;

const DownloadPage = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  return (
    <>
      <title>
        {t('pageSections.download')} | {t('pageSections.contactUs.name1')}
      </title>
      <Header isMobile={screens.lg ? false : true} />
      <div
        style={{
          ...styles.rootContainer,
          ...styles.backgroundImageStyle,
        }}
      >
        <div style={styles.cover}></div>
        <Row justify="center">
          <Col span={24} md={10}>
            <div style={styles.infoContainer}>
              <img style={styles.appIcon} src="/images/app/appicon.png" />
              <Title level={3} style={{ color: 'white' }}>
                {t('app.appName')}
              </Title>
              <Text style={{ color: 'white' }}>{t('app.subtitle')}</Text>
              <Text
                style={{
                  color: 'white',
                  marginTop: 20,
                  marginBottom: 10,
                  fontSize: 18,
                }}
              >
                {t('app.clickToDownload')}
              </Text>
              <div style={styles.downloadBtnContainer}>
                <a
                  target="blank"
                  href="https://apps.apple.com/app/id6448396737"
                  style={styles.downloadBtn}
                >
                  <FontAwesomeIcon
                    icon={faApple}
                    size="2x"
                    style={{ marginRight: 10 }}
                  />
                  <p style={{ padding: 0, margin: 0, fontWeight: 'bold' }}>
                    App Store
                  </p>
                </a>

                <a
                  target="blank"
                  href="https://play.google.com/store/apps/details?id=com.luniumall.grandintl"
                  style={styles.downloadBtn}
                >
                  <FontAwesomeIcon
                    icon={faGooglePlay}
                    size="2x"
                    style={{ marginRight: 10 }}
                  />
                  <p style={{ padding: 0, margin: 0, fontWeight: 'bold' }}>
                    Play Store
                  </p>
                </a>

                <a
                  target="blank"
                  href="https://luniumall.com/files/GrandIntl.apk"
                  style={styles.downloadBtn}
                >
                  <FontAwesomeIcon
                    icon={faAndroid}
                    size="2x"
                    style={{ marginRight: 10 }}
                  />
                  <p style={{ padding: 0, margin: 0, fontWeight: 'bold' }}>
                    Android
                  </p>
                </a>
              </div>
            </div>
          </Col>
          <Col span={24} md={14} style={styles.infoContainer}>
            <img style={styles.phoneInHand} src="/images/app/grandHand.png" />
          </Col>
        </Row>
      </div>
      <Footer isMobile={screens.lg ? false : true} />
    </>
  );
};

const styles = createStyle({
  rootContainer: {
    padding: 32,
    position: 'relative',
  },
  backgroundImageStyle: {
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#dcf5de',
    backgroundImage: 'url(/images/app/section-bg.png)',
  },
  cover: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)',
    backdropFilter: ' blur(6px)',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  appIcon: {
    borderRadius: 20,
    width: 100,
    marginBottom: 10,
  },
  downloadBtnContainer: {
    display: 'flex',
  },
  downloadBtn: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
    borderColor: '#2e78b7',
    backgroundColor: 'white',
    marginLeft: 10,
  },
  phoneInHand: {
    maxWidth: '100%',
    position: 'relative',
    bottom: -32,
  },
});

export default DownloadPage;
